.sideNav {
  position: absolute;
  left: 0;
  top: 5rem;
  min-height: calc(100% - 7rem);
  background-color: var(--primary-light-color);
  color: var(--primary-color-text);
  padding: 1.25rem 1.25rem;
  width: 3.75rem;
  transition: width 200ms ease;
  z-index: 100;

  .sideNavUL {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .navLink {
    padding: 0.5rem 0;
    text-align: left;
    height: 2.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
      font-size: 0.9em;
    }
    .navLink-text {
      margin-left: 0.75rem;
      display: none;
    }
  }

  .expander {
    text-align: right;
    padding: 0rem 0rem 0.5rem 0rem;
    cursor: pointer;

    i {
      font-size: 0.95em;
    }
  }
  i {
    color: var(--primary-color-text) !important;
  }

  &.expanded {
    width: 15.75rem;
    .navLink-text {
      display: block;
    }
  }
}
