.alpha-liegenschaft-verwaltung {
  /* header */
  .ls-verwaltung-headline {
  }

  .bee-error-label {
    display: block;
    margin-top: 5px;
    margin-bottom: -5px;
    font-weight: 400;
  }

  .p-progress-spinner {
    display: inline-flex;
    width: 28px;
    height: 28px;
    margin-left: 20px;
  }

  /* search-area */
  .audit-overview-search-area {
    margin: 0.5rem 0.25rem -2.25rem 0.25rem;
  }

  /* dataview select buttons */
  .p-dataview .p-dataview-header {
    display: flex;
    justify-content: end;
  }
  .p-dataview-header
    .p-dataview-layout-options.p-selectbutton.p-buttonset
    .p-button.p-button-icon-only {
    width: 2.5rem;
    padding: 0.75rem;
  }

  /* Cardview */
  .grid .alpha-liegenschaft-select-card {
    margin: 10px;
    cursor: pointer;

    /* Add-card */

    &.alpha-liegenschaft-select-add-card {
      min-height: 20rem;

      box-shadow: rgba(50, 50, 93, 0.25) 0px 8px 27px 3px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      border-radius: 4px;
      div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.35rem;
      }

      .p-card-body,
      .p-card-content {
        padding: 0px;
      }

      .bee-add-entry-card .p-button {
        font-size: 200%;
        width: 50%;
        height: auto;
      }
    }
    /* Card: Hover deactivated */

    &.alpha-liegenschaft-select-noHover:active {
      transform: scale(0.98);
      -ms-transform: scale(0.98);
      -webkit-transform: scale(0.98);
      filter: brightness(95%);
      transition: all 0.05s ease;
    }

    /* Card: Hover activated */

    &.alpha-liegenschaft-select-hover:hover {
      transform: scale(1.02);
      -ms-transform: scale(1.02);
      -webkit-transform: scale(1.02);
      transition: all 0.07s ease;
    }

    &.alpha-liegenschaft-select-hover:active {
      filter: brightness(0.9);
    }

    /* responsiveness card view */

    @media (min-width: 1px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 100%;
      }
    }

    @media (min-width: 600px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 45%;
      }
    }

    @media (min-width: 840px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 30%;
      }
    }

    @media (min-width: 1100px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 22%;
      }
    }

    @media (min-width: 1600px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 18.5%;
      }
    }

    @media (min-width: 2100px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 13%;
      }
    }

    @media (min-width: 2700px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 10%;
      }
    }

    @media (min-width: 3200px) {
      &.alpha-liegenschaft-select-add-card,
      & {
        width: 300px;
      }
    }
  }
}
