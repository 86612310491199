.imag-no-search-result {
  margin: 4rem 2rem 3rem 2rem;
  text-align: center;

  .no-search-animation {
    margin: 1rem auto 1.5rem auto;
    width: 16rem;
    margin-left: auto;
    margin-right: auto;
  }
}
