.bee-multi-select-container {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.bee-multi-select-container .p-inputwrapper,
.bee-multi-select-container input {
  width: 100%;
}

.bee-multi-select-container label {
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 3rem);
}
.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  width: unset;
}

.bee-multi-select-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 1.1rem);
  max-width: -moz-calc(100% - 1.1rem);
  max-width: calc(100% - 1.1rem);
  padding-left: 4px;
  padding-right: 6px;
}

.bee-multi-select-label-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-multi-select-label .bee-multi-select-label-required-star {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

.bee-multi-select-field {
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bee-multi-select-field-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-multi-select-field-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-multi-select-field-neutral {
}

.bee-multi-select-field-readOnly {
  border-style: none;
  padding: 0.82rem;
  opacity: 1 !important;
}
