.alpha-configEpTree-dialog.p-dialog {
  width: 80vw;
  /* secondary dialog styling*/
  .p-dialog-header {
    background-color: var(--secondary-dark-color);
    .p-dialog-title {
      color: var(--secondary-color-text);
    }
    .p-dialog-header-icons {
      button {
        color: var(--secondary-color-text);
      }
    }
    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--secondary-light-color) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:active {
        background: var(--secondary-color-68) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:focus {
        background: var(--secondary-color-76) !important;
        color: var(--secondary-color-text) !important;
      }
    }
  }

  .p-dialog-content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  /* table */
  .ep-table {
    padding-top: 0.5em;
    padding-bottom: 1.5em;

    .p-treetable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-treetable-thead th {
        white-space: nowrap;
      }
      .p-treetable-thead th,
      .p-treetable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-treetable-tbody td {
          padding: 0.25rem 0.75rem !important;
        }

        .p-treetable-emptymessage td {
          padding: 0.5rem 0.75rem !important;
        }
      }

      /* head */
      th {
        background: var(--primary-color);
        color: var(--primary-color-text);
        .ep-table-title {
          display: flex;
          align-items: center;
          div {
            padding-right: 1rem;
          }
          .p-button-icon-only {
            .icon-closeAll,
            .icon-openAll {
              &::before {
                font-weight: 700;
              }
            }
          }
        }
        .priceInput-input-col {
        }
      }

      /* titlecol */

      .ep-position-col {
        .priceInput-ep-code {
          font-size: 0.9em;
          color: var(--surface-500);
        }
      }
      tbody tr {
        .ep-position-col {
          display: flex;
          align-items: center;
        }
      }
    }

    /* col widths */

    .ep-position-col {
      width: 100%;
    }
    .pending {
      .p-progress-spinner {
        width: 1em;
        height: 0.9em;
        margin: 0.1em 0rem !important;
      }
    }
  }

  /* row colors */
  button {
    z-index: 1;
  }
  tr {
    background: none !important;
  }
  tr.c-1 {
    position: relative;
    font-weight: 600;
  }
  .c-1:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: 0;
    background: var(--primary-light-color) !important;
  }
  tr.c-2 {
    position: relative;
    font-weight: 500;
  }
  .c-2:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.18;
    z-index: 0;
    background: var(--primary-light-color) !important;
  }

  tr.c-3 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-4 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.13;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-5 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-6 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.08;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-7 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.05;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }
  tr.c-8 {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.03;
      z-index: 0;
      background: var(--primary-light-color) !important;
    }
  }

  /* footer */

  .user-btns {
    button:not(:last-of-type) {
      margin-right: 0.75em;
    }
  }
}

@media (max-width: 576px) {
  .alpha-user-dialog.p-dialog {
    width: 90vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .alpha-user-dialog.p-dialog {
    width: 70vw;
  }
}
