.bee-loading-spinner.p-progress-spinner {
  width: 1.5em !important;
  height: unset !important;
  margin: 0 0.75em !important;
  float: left;
}

.bee-loading-spinner-primary {
  --spinner-animation-start: var(--primary-color);
  --spinner-animation-40: var(--primary-dark-color);
  --spinner-animation-66: var(--primary-color);
  --spinner-animation-end: var(--primary-light-color);
}

.bee-loading-spinner-secondary {
  --spinner-animation-start: var(--secondary-color);
  --spinner-animation-40: var(--secondary-dark-color);
  --spinner-animation-66: var(--secondary-color);
  --spinner-animation-end: var(--secondary-dark-color);
}

.bee-loading-spinner-mixed {
  --spinner-animation-start: var(--primary-color);
  --spinner-animation-40: var(--secondary-color);
  --spinner-animation-66: var(--secondary-dark-color);
  --spinner-animation-end: var(--primary-light-color);
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--spinner-animation-start);
  }
  40% {
    stroke: var(--spinner-animation-40);
  }
  66% {
    stroke: var(--spinner-animation-66);
  }
  80%,
  90% {
    stroke: var(--spinner-animation-end);
  }
}
