.alpha-save-filter-dialog.p-dialog {
  width: 30vw;

  .p-dialog-content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  /* colors */
  .p-dialog-header {
    background-color: var(--primary-color);
    .p-dialog-title {
      color: var(--primary-color-text);
    }
    .p-dialog-header-icons {
      button {
        color: var(--primary-color-text);
      }
    }
    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--primary-light-color) !important;
        color: var(--primary-color-text) !important;
      }
      &:enabled:active {
        background: var(--primary-color-68) !important;
        color: var(--primary-color-text) !important;
      }
      &:enabled:focus {
        background: var(--primary-color-76) !important;
        color: var(--primary-color-text) !important;
      }
    }
  }

  .p-button {
    margin: 0.5rem 0 0 0;
  }
}

@media (max-width: 576px) {
  .alpha-save-filter-dialog.p-dialog {
    width: 90vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .alpha-save-filter-dialog.p-dialog {
    width: 40vw;
  }
}
