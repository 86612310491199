.bee-gallery-img {
  background-color: var(--secondary-color-32);
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.bee-gallery-img img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}

.bee-gallery-img-content {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.bee-gallery-img-content .p-skeleton {
  width: 100% !important;
  height: 100% !important;
}

.bee-gallery-img-content .bee-img-copyright {
  display: none;
}

.bee-gallery-img .bee-gallery-img-close-button {
  top: -10px;
  right: -10px;
  position: absolute;
  overflow: visible;
  z-index: 100;
}

.bee-gallery-img .bee-gallery-img-add-button .p-button.p-button-icon-only.p-button-rounded {
  width: 70%;
  height: 70%;
  font-size: 200%;
  position: absolute;
  align-content: center;
  justify-content: center;
}

.bee-gallery-img .copyright {
  opacity: 0;
}

.bee-gallery-img-readOnly .bee-img img {
  mask-size: unset;
  filter: none;
  opacity: 0.9;
  transform: none;
  backface-visibility: unset;
}

.bee-gallery-img-readOnly:hover .bee-gallery-img-add-button .p-button {
  transition: none !important;
  transform: none !important;
  pointer-events: none;
}

.bee-gallery-img-readOnly:hover .bee-img img {
  transition: none;
  filter: none;
  opacity: 0.9;
  transform: none;
}

.bee-gallery-img-readOnly.bee-gallery-img-placeholder {
  background-color: rgba(0, 0, 0, 0.12);
}

/* hover activated */

.bee-gallery-img-hover:hover .bee-img-title {
  transform: translate(0px, -28px);
  transition: transform 1s, opacity 1s ease-in-out;
  opacity: 1;
}

.bee-gallery-img-hover .bee-img-title {
  position: absolute;
  bottom: -28px;
  left: 0px;
  color: var( --primary-color-text);
  z-index: 10;
  background: var(--primary-color-50);
  height: 28px;
  width: 100%;
  padding: 0.2em;
  padding-left: 0.5em;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0;
}

.bee-gallery-img-hover:hover .bee-gallery-img-add-button .p-button {
  transition: transform 1s, filter 1s, opacity 1s ease-in-out;
  filter: grayscale(0%);
  transform: scale(1.1);
  opacity: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.bee-gallery-img-hover:hover .bee-gallery-img-close-button .p-button {
  transition: transform 1s, opacity 1s ease-in-out;
  opacity: 1;
  z-index: 100;
}

.bee-gallery-img-hover .bee-gallery-img-add-button {
  width: 30%;
  height: 30%;
  display: flex;
  align-items: center;
  text-align: center;
}

.bee-gallery-img-hover img {
  mask-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  filter: grayscale(75%) blur(1px);
  opacity: 0.8;
  transform: scale(1);
  backface-visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.bee-gallery-img-hover:hover img {
  transition: transform 1s, filter 1s, opacity 1s ease-in-out;
  filter: grayscale(0%) blur(0px);
  transform: scale(1.1);
  opacity: 1;
}

/* no hover */

.bee-gallery-img-noHover:active {
  transform: scale(0.95);
}

.bee-gallery-img-noHover .bee-img-title {
  position: absolute;
  bottom: 0px !important;
  left: 0px;
  color: var( --primary-color-text);
  z-index: 10;
  background: var(--primary-color-50);
  height: 28px;
  width: 100%;
  padding: 0.2em;
  padding-left: 0.5em;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  transform: none !important;
}

.bee-gallery-img-noHover .bee-gallery-img-add-button {
  width: 30%;
  height: 30%;
  display: flex;
  align-items: center;
  text-align: center;
}

.bee-gallery-img-noHover img {
  mask-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  overflow: hidden;
  opacity: 0.9;
}

.bee-gallery-img-readOnly.bee-gallery-img-noHover:active {
  transform: none;
}