.datenschutz {
  margin: 2rem 6rem 4rem 6rem;
  .datenschutz-content {
    text-align: left;
    font-size: 0.9em;

    .bold {
      font-weight: 800;
    }
    .paragraph-end {
      margin-bottom: 1rem;
    }
  }
}
