.bee-chip-clickable.bee-chip {
  cursor: pointer;
}

/* hide remove btn from primereact to exchange it with own */

.bee-chip .bee-chip-content .p-chip-remove-icon {
  visibility: hidden;
}

.bee-chip {
  position: relative;
  display: inline-block;
  font-size: 0.9em;
}

.bee-chip .bee-chip-remove-btn {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  height: 1rem;
  width: 1rem;
  margin-top: -0.5rem;
}

/* primary */
.bee-chip-primary .p-chip {
  color: var(--primary-color);
}

/* secondary */

.bee-chip-secondary .p-chip {
  color: var(--secondary-dark-color);
}
