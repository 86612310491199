.alpha-liegenschaft-page {
  .alpha-liegeschaft-form {
    margin-bottom: 3rem;

    /* nav */
    .breadcrumb ul {
      font-size: 0.9em;
      margin-left: -1.5em;
      margin-top: 0.75em;
    }

    /* headline */
    .liegenschaft-head {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headline {
        display: flex;
        align-items: center;
        .p-progress-spinner {
          width: 1.5em;
          margin: 0 0.75em;
          float: left;
        }

        /* show spinner */
        .invisibleSpinner {
          visibility: hidden;
        }
      }
    }

    /* btns */
    .action-btns {
      button {
        margin: 0 0.5rem;
      }
      .save-btn button {
        margin-right: 0rem;
      }
    }

    .alpha-liegenschaft-gd-form {
      h2 {
        margin-top: 0px;
      }
    }
  }

  /* gebaeude */
  .alpha-gebaeude-form {
    .p-accordion-header.geb-error a {
      color: var(--error-color) !important;
    }
    .p-accordion-header .p-accordion-header-link,
    .p-accordion-header.p-highlight:hover,
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
      .p-accordion-header-link {
      background-color: var(--secondary-color-12) !important ;
    }

    .add-building-btn {
      margin: 0.75em 0 0 0;
    }
  }

  /* footer */
  .liegenschaft-page-footer {
    display: flex;
    justify-content: space-between;
    margin: 1.75em 0;

    .cancle-btn {
      margin: 0 1em;
    }
  }

  .lsDelete {
    margin: 1.5em 0 0em 0;
  }
}
