.bee-search-bar {
  align-items: center;
  position: relative;
}

.bee-search-bar-button-col .p-button.p-button-icon-only {
  width: 2.8rem;
  height: 2.8rem;
  align-items: middle;
}

.bee-search-bar-button-col .p-button.p-button-icon-only .p-button-icon::before{
  font-weight:600;
}

.bee-search-bar-input-col {
  padding-right: 0px !important;
}

.bee-search-bar .search-cancel {
  position: absolute;
  right: 4.1rem;
}