.bee-nutzungsart-mix {
  position: relative;
}

.bee-nutzungsarten-mix-label {
  z-index: 100;
  display: block;
  position: absolute;
  pointer-events: none;
  top: -0.35rem !important;
  margin-left: 7.8px;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: var(--text-color-secondary);
  transition-duration: 0.2s;
  background-color: var(--label-background-color);
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 49px);
  padding-right: 6px;
}

.bee-nutzungsart-mix-input {
  display: block;
  padding: 0.77rem 0.77rem;
  border: 1px solid var(--primary-border-color);
  border-radius: 4px;
  align-items: stretch;
  width: 100%;
  min-width: 220px;
  margin: 5px 0;
}

.bee-nutzungsart-mix-input table {
  width: 100%;
}

.bee-nutzungsart-mix-input .bee-nutzungsarten-table-title {
  width: 20%;
  min-width: 100px;
  text-align: left;
  padding: 5px;
  padding-left: 3%;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bee-nutzungsart-mix-input .bee-nutzungsarten-table-percentage {
  width: 10%;
  max-width: 60px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* readOnly */
.bee-nutzungsart-mix-readOnly {
  border-style: none;
  padding: 0.83rem;
  opacity: 1 !important;
}

.bee-nutzungsart-mix-readOnly .p-slider {
  opacity: 1 !important;
  pointer-events: none;
}

/* required */
.bee-nutzungsart-mix-label .bee-nutzungsart-mix-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-nutzungsart-mix-label .bee-nutzungsart-mix-required {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

/* disabled */
.bee-nutzungsart-mix-disabled {
  pointer-events: none;
  opacity: 0.38;
}

/* formstate */
.bee-validated-nutzungsart-mix-label {
  padding: 4px;
  padding-top: 0px;
}

.bee-nutzungsart-mix-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-nutzungsart-mix-error {
  border-color: var(--error-color);
  border-width: 2px;
}
