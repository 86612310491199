.dl-property-detail {
  margin: 0 0 2rem 0;

  .property-detail-headline {
    display: flex;

    padding-bottom: 0px;

    h2 {
      margin: 0;
    }
    button.p-button {
      font-size: 1.5em;
      margin-top: 1em;
      margin-left: 0.5em;
      margin-bottom: 1em;
      width: auto;
      max-width: 2.25rem;
      min-width: 2.25rem;
      border: 1px solid !important;

      .p-button-icon {
        font-size: 1rem;
      }
    }
  }
  .property-detail-info {
    padding-top: 0px;
  }

  .property-detail-switch {
    display: flex;
    padding-bottom: 0px;
    align-items: center;

    .bee-switch {
      margin-top: auto;
      width: 100%;
      max-width: 28em;
      .bee-switch-input {
        border: none;
        margin-top: auto;
        margin-bottom: 0px;
        padding-left: 0rem;

        .p-inputswitch.p-disabled {
          opacity: 0.38 !important;
        }
      }
    }
    /* status = progress */
    .p-progress-spinner {
      width: 1em;
      height: 0.9em;
      margin: 0 0.25rem;
    }
    .status-factorChange {
      /* status = error */
      &.error {
        color: var(--error-dark-color);
        i {
          margin: 0 0.1rem 0 0.5rem;
          color: var(--error-dark-color) !important;
        }
      }
    }
  }

  .property-detail-img {
    padding-left: 3rem;
    display: flex;
    justify-content: flex-end;

    > div {
      height: 17.5em;
      max-width: 500px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        object-position: 50% 50%;
        border: 1px solid var(--surface-300);
      }
    }
  }

  @media screen and (max-width: '992px') {
    .md-invisible {
      display: none;
    }
  }
}
