.header {
  padding-top: 0.25rem;
  background-color: var(--primary-color);
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .bee-icon {
    margin: 1rem;
    font-size: 0.95em;
  }
}
