.bee-dropdown-container {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.bee-dropdown-container .p-dropdown {
  width: 100%;
}

.bee-dropdown-container label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 2.5rem - 6px);
  max-width: -moz-calc(100% - 2.5rem - 6px);
  max-width: calc(100% - 2.5rem - 6px);
  padding-right: 6px;
}

.bee-dropdown-label-required {
  padding-left: 3px;
  padding-right: 4px;
}

.bee-dropdown-label-required-star {
  position: absolute;
  right: 0px;
  padding-left: inherit;
  padding-right: inherit;
  max-width: 100%;
}

.bee-dropdown-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-dropdown-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-dropdown-neutral {
}

.bee-dropdown-readOnly {
  border-color: transparent !important;
  opacity: 1 !important;
}

.bee-dropdown-readOnly .p-dropdown-trigger-icon {
  display: none;
}
