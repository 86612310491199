.dl-property-factor {
  /* datatable */
  .p-datatable-table {
    /* column ellipsis + borders */
    tr {
      .dl-property-factor-header-leistungsgr {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        i {
          margin-right: 0.25em;
        }
        span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      td,
      th {
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-of-type {
          border-width: 0 1px 1px 0;
        }
      }
    }

    /* header */
    .p-column-header-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /* inputfields */
    .p-datatable-tbody {
      tr > td {
        padding: 0.5em 0.5em !important ;
      }

      .bl-col:not(:first-of-type) {
        padding-left: 0.5rem;
      }

      .status-factorChange {
        /* status = progress */
        .p-progress-spinner {
          width: 1em !important;
          height: 0.9em !important;
          margin: 0 0.25rem !important;
          float: unset;
        }
        /* status = error */
        &.error {
          color: var(--error-dark-color);
          i {
            margin: 0 0.1rem 0 0.5rem;
            color: var(--error-dark-color) !important;
          }
        }
      }

      .load-factor.pending {
        .p-progress-spinner {
          width: 1em;
          height: 0.9em;
          margin: 0 0.25rem;
        }
      }

      .p-editable-column {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .bl-col-body {
        display: inline-block;
        .bee-num-input-container {
          margin: 0px;
          input {
            padding: 0px !important;
            width: 2.8em;
          }
        }
      }
    }

    .dl-property-factor-facCol {
      width: clamp(12em, 50%, 15em);
    }
  }
}
