.dl-overview-properties {
  /* Cardview */
  .grid .dl-liegenschaft-select-card {
    display: flex;
    margin: 10px;
    cursor: pointer;

    /* responsiveness card view */

    @media (min-width: 1px) {
      & {
        width: 100%;
      }
    }

    @media (min-width: 600px) {
      & {
        width: 45%;
      }
    }

    @media (min-width: 1100px) {
      & {
        width: 30%;
      }
    }

    @media (min-width: 1500px) {
      & {
        width: 22%;
      }
    }

    @media (min-width: 1750px) {
      & {
        width: 18.5%;
      }
    }

    @media (min-width: 2350px) {
      & {
        width: 13%;
      }
    }

    @media (min-width: 3000px) {
      & {
        width: 10%;
      }
    }

    @media (min-width: 3200px) {
      & {
        width: 350px;
      }
    }
  }
}
