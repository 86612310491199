.bee-num-select-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bee-num-select-container .p-inputnumber,
.bee-num-select-container input {
  width: 100%;
}

.bee-num-select-container .p-inputnumber-input {
  overflow-x: clip;
  text-overflow: ellipsis;
}

.bee-num-select-container label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 2.5rem - 6px);
  max-width: -moz-calc(100% - 2.5rem - 6px);
  max-width: calc(100% - 2.5rem - 6px);
  padding-right: 6px;
}

.bee-num-select-container
  .p-inputnumber-button-group
  .p-button:not(a):not(.p-disabled):hover {
  background: var(--primary-light-color);
}
/* required */
.bee-num-select-label-required {
  padding-left: 3px;
  padding-right: 4px;
}

.bee-num-select-label-required-star {
  position: absolute;
  right: 0px;
  padding-left: inherit;
  padding-right: inherit;
  max-width: 100%;
}
/* formstate */

.bee-num-select-valid .p-inputnumber-input {
  border-color: var(--valid-color);
  border-width: 2px;
  margin-right: -2px;
}

.bee-num-select-error .p-inputnumber-input {
  border-color: var(--error-color);
  border-width: 2px;
  margin-right: -2px;
}

/* readOnly */
.bee-num-select-readOnly .p-inputnumber-input {
  border-color: transparent !important;
  opacity: 1 !important;
  margin-right: -2px;
}

.bee-num-select-readOnly .p-inputnumber-button-group {
  display: none !important;
}
