.bee-error-label {
  color: var(--error-color);
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.bee-error-label-small {
  font-size: small;
}

.bee-error-label-medium {
  font-size: medium;
}

.bee-error-label-large {
  font-size: large;
}