.alpha-target-prices-dialog.p-dialog {
  width: 85vw;

  .p-dialog-content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  /* colors */
  .p-dialog-header {
    background-color: var(--secondary-dark-color);
    .p-dialog-title {
      color: var(--secondary-color-text);
    }
    .p-dialog-header-icons {
      button {
        color: var(--secondary-color-text);
      }
    }
    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--secondary-light-color) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:active {
        background: var(--secondary-color-68) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:focus {
        background: var(--secondary-color-76) !important;
        color: var(--secondary-color-text) !important;
      }
    }
  }

  /* content */

  .alpha-target-prices-input-table {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper,
    .p-datatable-scrollable-wrapper {
      border-radius: 4px;
      overflow-x: scroll;

      /* allg */
      .p-datatable-thead th {
        white-space: nowrap;
      }
      .p-datatable-thead th,
      .p-datatable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-datatable-tbody td,
        .p-datatable-tfoot td {
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
        }

        .p-datatable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      .p-datatable-scrollable-header-box,
      .p-datatable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;

          .p-inputtext {
            background: transparent;
          }
        }
      }

      /* titlecol */

      tbody tr {
        position: relative;
      }

      .alpha-target-prices-adresse-col {
        left: 15em;
      }

      /* prices */
      .alpha-target-prices-cols {
        text-align: right;

        input {
          border: none;
          background: transparent;
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .alpha-target-prices-dialog.p-dialog {
    width: 100vw;
  }
}
