.download-combindes-positions {
  float: right;
  margin: 0em 0em 0em 0em;

  i {
    margin-right: 0.5em;
    &:before {
      font-weight: 800 !important;
    }
  }
}
