.bee-num-input-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bee-num-input-container .p-inputnumber,
.bee-num-input-container input {
  width: 100%;
}

.bee-num-input-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 1.1rem);
  max-width: -moz-calc(100% - 1.1rem);
  max-width: calc(100% - 1.1rem);
  padding-left: 4px;
  padding-right: 6px;
}

.bee-num-input-label-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-num-input-label .bee-num-input-label-required-star {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

.bee-num-input {
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bee-num-input-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-num-input-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-num-input-neutral {
}

.bee-num-input-readOnly {
  border-style: none !important;
  padding: 0.82rem !important;
  opacity: 1 !important;
}
