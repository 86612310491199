.bee-listbox {
  margin-top: 5px;
  margin-bottom: 5px;

  /* checkbox */
  .p-checkbox {
    margin-right: 0.5em;
  }
  /* label */
  label {
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    max-width: -webkit-calc(100% - 1.25rem - 6px);
    max-width: -moz-calc(100% - 1.25rem - 6px);
    max-width: calc(100% - 1.25rem - 6px);
    padding-right: 6px;
    top: 2px !important;
    background-color: white;
    font-size: 12px;
    padding-left: 0.25em;
  }
  /* required */
  .bee-listbox-label-required {
    padding-left: 3px;
    padding-right: 4px;
  }

  .bee-listbox-label-required-star {
    position: absolute;
    right: 0px;
    padding-left: inherit;
    padding-right: inherit;
    max-width: 100%;
  }
  /* readOnly */
  .listbox-list-readOnly ul {
    pointer-events: none;
  }
}
