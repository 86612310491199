.bee-treeselect-liegenschaften {
  padding-top: 5px;
  padding-bottom: 5px;
  .p-tree {
    width: 100%;
    padding: 0;

    .p-tree-header {
      padding: 0.75rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.87);
      background: #ffffff;
      margin: 0;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .p-tree-filter-container {
        margin-bottom: 0rem;
      }
    }

    .p-tree-container {
      height: 17em;
    }
  }
  label {
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    max-width: calc(100% - 1.25rem - 6px);
    padding-right: 6px;
    top: 5px !important;
    background-color: white;
    font-size: 12px;
    padding-left: 0.25em;
  }
}
