.bee-img {
  width: 100%;
  height: 100%;
}

.bee-img-copyright {
  text-align: right;
}

.bee-img-title-right {
  text-align: right;
}

.bee-img-title-left {
  text-align: left;
}

.bee-img-title-center {
  text-align: center;
}