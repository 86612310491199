.al-noPhases {
  h2 {
    padding-top: 1rem;
  }
  .noPhases-content {
    text-align: center;
  }

  .animation-box div {
    height: 25rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
}
