.bee-timeline {
  .timeline {
    width: 70%;
    margin: 2rem auto 0 auto;

    .p-timeline {
      justify-content: center;
      align-items: center;

      .p-chip {
        .p-chip-text {
          min-width: 0.65em;
        }
      }

      /* active phase  */
      .active {
        .bee-chip .p-chip {
          background-color: #e3c256;
        }
      }
      .wasActive {
        .bee-chip .p-chip {
          background-color: #1b5e207d;
        }
      }
      .willActive {
        .bee-chip .p-chip {
          background-color: var(--primary-color-50);
        }
      }
      .noParticipation {
      }

      /* currently clicked phase  */
      .currPhase {
        .p-chip {
          font-size: 2em !important;
          padding: 0 1.2rem;
          border-radius: 2rem;
        }
      }

      .phase {
        margin-bottom: 0.5rem;
        margin-right: 1.25em;

        div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .p-timeline {
      align-items: center;
      .p-timeline-event {
        max-width: 30em;
        /* numbers */
        .p-timeline-event-separator {
          cursor: pointer;
          .p-chip {
            font-size: 0.8rem;
          }
        }
        /* separators */
        .p-timeline-event-connector {
          margin: 0 0.5rem;
        }

        /* content */
        .p-timeline-event-content {
          text-align: left;
          font-size: 0.9em;
          margin-left: -0.5em;
        }

        &:last-of-type {
          /* numbers */
          .p-timeline-event-separator {
            margin-right: 5rem;
          }
        }
        &:first-of-type {
          margin-left: 5rem;
        }
      }
    }

    .non-clickable {
      cursor: default;
      opacity: 0.6;
    }
    .clickable {
      cursor: pointer;
    }
  }
  .participation-toggle {
    &.participation-false {
      .bee-switch-input {
        border-color: var(--error-dark-color);
        .p-inputswitch-slider {
          background: var(--error-dark-color);
          &:before {
            background: var(--error-color);
          }
        }
      }
    }
    .bee-switch-disabled .bee-switch-input {
      opacity: 1;
    }
  }
}
.bold {
  font-weight: 500;
}
.visibilityHidden {
  visibility: hidden;
}
