.bee-doc-table {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bee-doc-table .p-datatable-wrapper {
  border-radius: 4px;
  overflow: hidden;
}

.bee-doc-table .p-datatable-thead th,
.bee-doc-table .p-datatable-tbody td,
.bee-doc-table .p-datatable-tbody td p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* padding for first and last col */
.bee-doc-table table th:first-child,
.bee-doc-table table td:first-child {
  padding-left: 1rem !important;
}

.bee-doc-table table th:last-child,
.bee-doc-table table td:last-child {
  padding-right: 1rem !important;
}

/* padding for header and body */
.bee-doc-table .p-datatable-thead th {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.bee-doc-table .p-datatable-tbody td {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.bee-doc-table .p-datatable-emptymessage td {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

/* cols */

.bee-doc-table-col-action {
  padding: 0 1rem !important;
}

.bee-doc-table-col-action-oneBtn {
  width: calc(7.5rem + 3px);
}

.bee-doc-table-col-action-twoBtn {
  width: calc(7.5rem + 3px);
}

.bee-doc-table-col-action button {
  margin-right: 0.5em;
}

.bee-doc-table-col-date {
  width: 6.5em;
}
.bee-doc-table-selectAll-col {
  width: 4em;
  .bee-checkbox-input {
    border: none;
  }
}
.bee-doc-table-col-title {
  width: 100%;
}

.bee-doc-table-col-size {
  width: 7em;
  padding: 0 1em !important;
}

.bee-doc-table-col-type {
  width: 5.5em;
}

/* checkbox */
.p-checkbox {
  .p-checkbox-box.p-highlight {
    border-color: var(--secondary-dark-color) !important;
    background: var(--secondary-dark-color) !important;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--secondary-color) !important;
  background: var(--secondary-color) !important;
  color: #ffffff;
}

/* button */
.bee-doc-table-downloadAll {
  i {
    margin-right: 0.5em;
  }
}

/* dark */
.bee-doc-table .bee-doc-table-downloadAll {
  padding: 0.5rem 1.15rem;
}
.bee-doc-table.bee-doc-table-dark table .p-datatable-thead tr th {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

/* light */

.bee-doc-table.bee-doc-table-light table .p-datatable-thead tr th {
  font-size: 1.1em;
  color: var(--primary-color);
}

.bee-doc-table.bee-doc-table-light table .p-datatable-tbody tr:nth-child(odd) {
  background-color: var(--primary-color-4);
}

/* responsive */

@media (max-width: 450px) {
  /* hide header */
  .bee-doc-table table thead {
    display: none;
  }

  /* position content */
  .bee-doc-table table,
  .bee-doc-table table tbody,
  .bee-doc-table table tr,
  .bee-doc-table table td {
    display: block;
    width: 100%;
    text-align: left;
    float: left;
    clear: left;
    border: 0 none;
  }
  .bee-doc-table table tbody tr {
    margin-bottom: 10px;
  }

  .bee-doc-table table td p,
  .bee-doc-table .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td,
  .bee-doc-table table td div {
    text-align: left !important;
    position: relative;
    border: none;
  }
  /* use headername as new set title in each row  */
  .bee-doc-table table td p::before,
  .bee-doc-table table td div::before {
    content: attr(headername);
    padding: 0.4rem;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    margin: -0.4em 1em -0.6em 0.4rem;
    font-weight: bold;
    color: var(--primary-color);
  }

  /* change padding */
  .bee-doc-table table th:first-child,
  .bee-doc-table table td:first-child {
    padding-left: 0.4rem !important;
  }
  .bee-doc-table table > tbody > tr:first-child td:first-child {
    padding-top: 10px !important;
  }
  .bee-doc-table table > tbody > tr td p {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
  }
  .bee-doc-table table tbody > tr > td:last-child {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    padding-bottom: 10px !important;
  }

  /* alternate row colors */
  .bee-doc-table table tbody tr:nth-child(odd) {
    background: var(--primary-color-4);
  }
}
