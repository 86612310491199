.bee-arrow-diff {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  margin-left: 0.75em;
  white-space: nowrap;
  max-height: 2em;
  /* icon allg */
  .bee-arrow-diff-icon {
    padding: 0.1em 0em;
    .bee-arrow {
      &::before {
        font-weight: 600 !important;
        font-size: 0.9em;
      }
    }
  }

  /* text allg*/
  .bee-arrow-diff-text {
    font-size: 0.75em;
    padding-right: 0.45em;
  }

  /* rotation + color */
  &.bee-arrow-dive {
    position: relative;
    //background
    .bee-arrow::before {
      transform: rotate(90deg);
      color: var(--valid-dark-color);
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      border-radius: 4px;
      background: var(--valid-dark-color) !important;
    }
    .bee-arrow-diff-text {
      color: var(--valid-dark-color);
      /*      margin-left: -0.25em; */
    }
  }

  &.bee-arrow-dip {
    position: relative;

    .bee-arrow::before {
      transform: rotate(45deg);
      color: var(--valid-dark-color);
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      border-radius: 4px;
      background: var(--valid-dark-color) !important;
    }
    .bee-arrow-diff-text {
      color: var(--valid-dark-color);
      /*  margin-top: -0.5em;
      margin-left: -0.15em; */
    }
  }
  &.bee-arrow-steady {
    position: relative;
    .bee-arrow::before {
      color: var(--surface-500);
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      border-radius: 4px;
      background: var(--surface-500) !important;
    }
    .bee-arrow-diff-text {
      color: var(--surface-500);
      /*   margin-left: 0.25em; */
    }
  }
  &.bee-arrow-bump {
    position: relative;
    .bee-arrow::before {
      transform: rotate(-45deg);
      color: var(--error-dark-color);
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      border-radius: 4px;
      background: var(--error-dark-color) !important;
    }
    .bee-arrow-diff-text {
      color: var(--error-dark-color);
      /*    margin-bottom: -0.5em; */
    }
  }
  &.bee-arrow-jump {
    position: relative;
    .bee-arrow::before {
      transform: rotate(-90deg);
      color: var(--error-dark-color);
    }
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      border-radius: 4px;
      background: var(--error-dark-color) !important;
    }
    .bee-arrow-diff-text {
      color: var(--error-dark-color);
      /*    margin-left: -0.25em; */
    }
  }
}
