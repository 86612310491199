.ep-lv-phase-date {
  font-size: 0.75em;
}

.ep-lv-lvUpload-btn {
  button {
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
}

.ep-lv-phase-epBtn {
  margin-top: 0.5em;
  flex-wrap: nowrap !important;
  &.grid {
    margin-top: 0em !important;
  }
  button {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  &.download {
    text-decoration: underline;
    text-underline-offset: 0.2em;
    color: var(--secondary-dark-color);

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  button {
    color: var(--secondary-dark-color);
    padding: 0px;
    max-width: 100%;

    .p-button-label {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .download-btn {
    padding-right: 0px;
    max-width: 100%;
    overflow: hidden;
  }
  .delete-btn {
    padding-right: 0.25em !important;
    button {
      margin-left: 0.25em;
      width: unset;
      min-width: 1em;
    }
  }
  i {
    font-size: 0.9em;
    margin-right: 0.5em;
  }
  .col {
    padding-right: 0em;
  }
}
