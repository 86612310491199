.dl-liegenschaft-dialog.p-dialog {
  width: 80vw;

  .p-dialog-header {
    padding: 0 1.5rem;
  }

  .p-dialog-content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  /* secondary dialog styling*/
  .p-dialog-header {
    background-color: var(--secondary-dark-color);
    .p-dialog-title {
      color: var(--secondary-color-text);
    }
    .p-dialog-header-icons {
      button {
        color: var(--secondary-color-text);
      }
    }
    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--secondary-light-color) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:active {
        background: var(--secondary-color-68) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:focus {
        background: var(--secondary-color-76) !important;
        color: var(--secondary-color-text) !important;
      }
    }
  }

  /* content */
  .headline {
    display: flex;
    align-items: center;
    height: 4em;
    h1 {
      margin: 0;
      font-size: 1.5em;
    }
    .p-progress-spinner {
      width: 1.5em;
      margin: 0 0.75em;
      float: left;
    }

    /* show spinner */
    .invisibleSpinner {
      visibility: hidden;
    }
  }

  /* headline */
  .p-dialog-content {
    padding: 0.5rem 2rem;

    /* gebaeude */
    .liegenschaft-gebaeude-form {
      .p-accordion-header.geb-error a {
        color: var(--error-color) !important;
      }
      .p-accordion-header .p-accordion-header-link,
      .p-accordion-header.p-highlight:hover,
      .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
      .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
        .p-accordion-header-link {
        background-color: var(--secondary-color-12) !important ;
      }
    }
    .lsDialog-btns {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0;
    }
  }
}

@media (max-width: 576px) {
  .dl-liegenschaft-dialog.p-dialog {
    width: 85vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .dl-liegenschaft-dialog.p-dialog {
    width: 65vw;
  }
}
