.alpha-dl-factorOverTime {
  padding-top: 10px;
  padding-bottom: 10px;

  .alpha-dl-factorOverTime-table {
    margin-bottom: 2em;
    width: 100%;
  }
  .alpha-dl-factorOverTime-prop-table {
    margin-bottom: 3em;
  }
  .p-treetable {
    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }
    .p-treetable-scrollable-header-box {
      margin-right: 0px !important;
    }

    .p-treetable-thead th,
    .p-treetable-tbody td,
    .p-treetable-tbody td p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    /* padding for first and last col */
    table th:first-child,
    table td:first-child {
      padding-left: 1rem !important;
    }

    table th:last-child,
    table td:last-child {
      padding-right: 1rem !important;
    }

    /* padding for header and body */

    .p-treetable-tbody td {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    .p-treetable-emptymessage td {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }

    /* cols */
    .alpha-dl-factorOverTime-bl-col,
    .alpha-dl-factorOverTime-prop-col {
      position: relative;
      width: 10em;
      background-color: var(--primary-color-12);
    }
    .alpha-dl-factorOverTime-blFac-col,
    .alpha-dl-factorOverTime-blProp-col {
      width: 7em;
      .alpha-dl-factorOverTime-blFac-value,
      .alpha-dl-factorOverTime-blProp-value {
        display: inline-flex;
      }
      .alpha-dl-factorOverTime-blFac-num,
      .alpha-dl-factorOverTime-blProp-num {
        min-width: 2.5em;
        .bee-num-input-container {
          margin: 0px;
          input {
            padding: 0px !important;
            width: 2.8em;
            background: transparent;
          }
        }
      }

      .phase-period {
        font-size: 0.8em;
      }
    }

    /* dark */

    table .p-treetable-thead tr th {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }
  }
}
