@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?10484225');
  src: url('../font/fontello.eot?10484225#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?10484225') format('woff2'),
       url('../font/fontello.woff?10484225') format('woff'),
       url('../font/fontello.ttf?10484225') format('truetype'),
       url('../font/fontello.svg?10484225#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?10484225#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-double-left:before { content: '\e800'; } /* '' */
.icon-right:before { content: '\e801'; } /* '' */
.icon-chart:before { content: '\e802'; } /* '' */
.icon-haushaelter:before { content: '\e803'; } /* '' */
.icon-agreement:before { content: '\e804'; } /* '' */
.icon-trash-can:before { content: '\e805'; } /* '' */
.icon-company:before { content: '\e806'; } /* '' */
.icon-elevator:before { content: '\e807'; } /* '' */
.icon-energy:before { content: '\e808'; } /* '' */
.icon-euro:before { content: '\e809'; } /* '' */
.icon-aufzug:before { content: '\e80a'; } /* '' */
.icon-energie:before { content: '\e80b'; } /* '' */
.icon-entsorgung:before { content: '\e80c'; } /* '' */
.icon-pruefer:before { content: '\e80d'; } /* '' */
.icon-closeAll:before { content: '\e80e'; } /* '' */
.icon-openAll:before { content: '\e80f'; } /* '' */
.icon-logout:before { content: '\e810'; } /* '' */
.icon-questions:before { content: '\e811'; } /* '' */
.icon-export-pdf:before { content: '\e812'; } /* '' */
.icon-export-excel:before { content: '\e813'; } /* '' */
.icon-download:before { content: '\e814'; } /* '' */
.icon-filter:before { content: '\e815'; } /* '' */
.icon-reminders:before { content: '\e816'; } /* '' */
.icon-timer:before { content: '\e817'; } /* '' */
.icon-country:before { content: '\e818'; } /* '' */
.icon-eye:before { content: '\e819'; } /* '' */
.icon-error:before { content: '\e81a'; } /* '' */
.icon-download-1:before { content: '\e81b'; } /* '' */
.icon-upload:before { content: '\e81c'; } /* '' */
.icon-view:before { content: '\e81d'; } /* '' */
.icon-documents:before { content: '\e81e'; } /* '' */
.icon-stack-of-paper:before { content: '\e81f'; } /* '' */
.icon-document:before { content: '\e820'; } /* '' */
.icon-user:before { content: '\e821'; } /* '' */
.icon-check:before { content: '\e822'; } /* '' */
.icon-times:before { content: '\e823'; } /* '' */
.icon-settings:before { content: '\e824'; } /* '' */
.icon-schedule:before { content: '\e825'; } /* '' */
.icon-edit:before { content: '\e826'; } /* '' */
.icon-search:before { content: '\e827'; } /* '' */
.icon-sustainability:before { content: '\e828'; } /* '' */
.icon-lock:before { content: '\e829'; } /* '' */
.icon-important:before { content: '\e82a'; } /* '' */
.icon-warning:before { content: '\e82b'; } /* '' */
.icon-right-arrow:before { content: '\e82c'; } /* '' */
.icon-country-warning:before { content: '\e82d'; } /* '' */
.icon-prize:before { content: '\e82e'; } /* '' */
.icon-star-filled:before { content: '\e82f'; } /* '' */
.icon-key:before { content: '\e874'; } /* '' */
.icon-pforte:before { content: '\e8a5'; } /* '' */
.icon-aussenanlagen:before { content: '\e8a6'; } /* '' */
.icon-revierdienst:before { content: '\e8a7'; } /* '' */
.icon-empfang:before { content: '\e8a8'; } /* '' */
.icon-schaedlingsbekaempfung:before { content: '\e8a9'; } /* '' */
.icon-gebauedereinigung:before { content: '\e8aa'; } /* '' */
