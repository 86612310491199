.alpha-setup-phase-to-user {
  .bee-tri-state-input {
    border: none;
  }

  .alpha-phase-to-user-table {
    /* datatable */
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper {
      border-radius: 4px;
      overflow: hidden;
    }

    .p-datatable-thead th,
    .p-datatable-tbody td,
    .p-datatable-tbody td div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    /* padding for first and last col */
    table th:first-child,
    table td:first-child {
      padding-left: 1rem !important;
    }
    table th:last-child,
    table td:last-child {
      padding-right: 1rem !important;
    }

    /* padding for header and body */
    .p-datatable-thead {
      vertical-align: baseline;
      th {
        padding-top: 1rem !important;
        padding-bottom: 0.75rem !important;
      }
    }

    .p-datatable-tbody td {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .p-datatable-emptymessage td {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    tr {
      vertical-align: baseline;
    }

    /* content */

    .lv-table-liegenschaft {
      .lv-number {
        font-size: 0.8em;
      }
    }
    //server request states
    .user-to-phase-checkbox {
      display: flex;
      .bee-validated-checkbox {
        width: unset;
      }
      .bee-loading-spinner {
        margin: 0.8em 0 !important;
      }

      .status-userChange.error {
        margin: 1em 0 !important;
        i {
          color: var(--error-dark-color) !important;
          margin-right: 0.25em;
        }
      }
    }

    //checkbox allg

    .bee-checkbox-input {
      border: none;
      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: var(--secondary-dark-color);
        background: var(--secondary-dark-color);
      }
    }

    /* dark */

    table .p-datatable-thead tr th {
      background: var(--primary-color);
      color: var(--primary-color-text);
    }
  }
  /* cols */

  .Nutzer-col {
    // padding: 0 1rem !important;
    width: 20%;
  }

  .Unternehmen-col {
    width: 20%;
  }

  .user-phase {
    padding-left: 1em !important;
  }
}
