.alpha-options-page {
  //tabview
  .p-tabview {
    .p-tabview-nav-container {
      font-size: 1.2em;
      //selected tab
      .p-tabview-selected {
        a {
          color: var(--secondary-dark-color) !important ;
        }
      }
    }
    .p-tabview-panels {
      background: var(--surface-100);
    }
  }
}
