.alpha-arwarding-dialog.p-dialog {
  width: 85vw;

  .p-dialog-content {
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  /* secondary dialog styling*/
  .p-dialog-header {
    background-color: var(--secondary-dark-color);
    .p-dialog-title {
      color: var(--secondary-color-text);
    }
    .p-dialog-header-icons {
      button {
        color: var(--secondary-color-text);
      }
    }
    .p-dialog-header-icon {
      &:enabled:hover {
        background: var(--secondary-light-color) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:active {
        background: var(--secondary-color-68) !important;
        color: var(--secondary-color-text) !important;
      }
      &:enabled:focus {
        background: var(--secondary-color-76) !important;
        color: var(--secondary-color-text) !important;
      }
    }
  }

  /* content */
  .headline {
    display: flex;
    align-items: center;
    height: 4em;
    h1 {
      margin: 0;
      font-size: 1.5em;
    }
    .p-progress-spinner {
      width: 1.5em;
      margin: 0 0.75em;
      float: left;
    }

    /* show spinner */
    .invisibleSpinner {
      visibility: hidden;
    }
  }

  /* headline */
  .p-dialog-content {
    padding: 0.5rem 2rem;

    /* table */
    .alpha-award-dialog-table {
      padding-top: 10px;
      padding-bottom: 10px;

      .p-datatable-wrapper,
      .p-datatable-scrollable-wrapper {
        border-radius: 4px;
        overflow: hidden;
      }
      /* allg */
      .p-datatable-scrollable-header-box,
      .p-datatable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      .p-datatable-thead th {
        white-space: nowrap;
      }
      .p-datatable-thead th,
      .p-datatable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      table {
        tr {
          position: relative;
          &:last-of-type {
            border-bottom: 1px solid black;
          }
        }
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-datatable-tbody td {
          padding: 0.25rem 0.75rem;
        }

        .p-datatable-emptymessage td {
          padding: 0.5rem 0.75rem !important;
        }
      }

      /* head */
      .p-datatable-thead tr th {
        background: var(--primary-color);
        color: var(--primary-color-text);
      }
      /* content */
      .p-datatable-tbody td {
        input {
          background-color: transparent;
          text-align: right;
        }
      }

      /* cols */
      .alpha-award-dialog-table-selection-col {
        width: 2.8em;
        padding-right: 0em !important;
        padding-left: 0.25em !important;
      }
      .alpha-award-dialog-table-fav-col {
        width: 1.75em;
        padding-left: 0.25em !important;
        padding-right: 0.25em !important;

        .alpha-awarding-dialog-fav-icon {
          &:before {
            color: #f8c034;
          }
        }
      }
      .alpha-award-dialog-table-dl-col {
        width: 30%;
      }
      .alpha-award-dialog-table-name-col {
        width: 25%;
      }
      .alpha-award-dialog-table-price-col {
        width: 40%;
        text-align: right;
      }
      .alpha-award-dialog-table-info-col {
        width: 5em;
        & > div {
          display: flex;

          .alpha-awarding-dialog-info {
            margin: 0 0.25em;
          }
        }
      }
      .alpha-award-dialog-table-quantity-col {
        width: 10%;
      }
    }

    /* bottom area */

    .alpha-awarding-dialog-target-area {
      background: var(--surface-200);
      padding: 1.5em 1em 0.25em 1em;
      border-radius: 4px;
      .alpha-awarding-dialog-target-price {
        b {
          width: 7em;
        }
        .bee-cur-input-container {
          input {
            text-align: right;
            background: transparent;
          }
        }
      }
      .alpha-awarding-dialog-target-diff {
        b {
          width: 18em;
        }
        div {
          text-align: right;
          width: 100%;
          padding-right: 0.75em;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .dl-liegenschaft-dialog.p-dialog {
    width: 85vw;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .dl-liegenschaft-dialog.p-dialog {
    width: 65vw;
  }
}
