.alpha-vergabe-ansicht {
  .alpha-vergabe-headline {
    .bee-dropdown-container {
      min-width: 15em;
    }
  }
  .alpha-vergabe-area {
    .alpha-target-pricing {
      float: right;
      margin-top: -0.5em;
    }
  }
}
