.bee-cur-input-container {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.bee-cur-input-container .p-inputtext {
  padding-right: 2em;
}

.bee-cur-input-container .p-inputnumber,
.bee-cur-input-container input {
  width: 100%;
}

.bee-cur-input-label {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 1.1rem);
  max-width: -moz-calc(100% - 1.1rem);
  max-width: calc(100% - 1.1rem);
  padding-left: 4px;
  padding-right: 6px;
}

.bee-cur-input-label-required {
  padding-right: 3px;
  padding-left: 4px;
}

.bee-cur-input-label .bee-cur-input-label-required-star {
  position: absolute;
  right: 0px;
  max-width: 100%;
  padding-right: inherit;
}

.bee-cur-input-field {
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* icon */

.bee-cur-input-container.p-input-icon-right {
  .currency-euro-sign {
    right: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    margin-top: -0.77em;
    font-size: 1.3em;
  }
}

.bee-cur-input-field-valid {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-cur-input-field-error {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-cur-input-field-neutral {
}

.bee-cur-input-container .bee-cur-input-field-readOnly {
  border-style: none;

  opacity: 1 !important;
}
