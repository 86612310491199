.alpha-filter-config-table {
  padding-top: 10px;
  padding-bottom: 10px;

  .p-datatable-wrapper {
    border-radius: 4px;
    overflow: hidden;
  }
  table {
    .p-datatable-thead th,
    .p-datatable-tbody td,
    .p-datatable-tbody td p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    /* padding for first and last col */
    th:first-child,
    td:first-child {
      padding-left: 1rem !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 1rem !important;
    }
    /* padding for header and body */
    .p-datatable-thead tr th {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

      background: var(--primary-color);
      color: var(--primary-color-text);
    }

    .p-datatable-tbody td {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }

    .p-datatable-emptymessage td {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }

    /* cols */

    .config-name-col {
      padding: 0 1rem !important;
      width: 100%;
    }

    .action-col {
      width: 7em;
      padding: 0 1em !important;

      button {
        margin-right: 0.5em;
      }
    }
  }
}
