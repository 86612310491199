.bee-checkbox {
  display: block;
  position: relative;
  width: 100%;
}

.bee-checkbox-input {
  display: block;
  position: relative;
  padding: 0.75rem 0.75rem;
  border-width: 1px;
  border-color: var(--primary-border-color);
  border-style: solid;
  border-radius: 4px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 1;
}

.bee-checkbox-input .p-checkbox:hover {
  position: relative;
  z-index: 10;
}

.bee-checkbox .p-checkbox {
  display: inline-flex;
}

.bee-checkbox-valid .bee-checkbox-input {
  border-color: var(--valid-color);
  border-width: 2px;
}

.bee-checkbox-error .bee-checkbox-input {
  border-color: var(--error-color);
  border-width: 2px;
}

.bee-checkbox-readOnly .bee-checkbox-input {
  border-color: transparent !important;
  opacity: 1 !important;
}

.bee-checkbox-readOnly .p-checkbox-box {
  opacity: 1 !important;
}

.bee-checkbox-label {
  display: block;
  position: absolute;
  pointer-events: none;
  top: -0.35rem !important;
  margin-left: 7.8px;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: var(--text-color-secondary);
  transition-duration: 0.2s;
  background-color: var(--label-background-color);
  padding-left: 4px;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 1rem);
  max-width: -moz-calc(100% - 1rem);
  max-width: calc(100% - 1rem);
  padding-right: 6px;
}

.bee-checkbox-label-required {
  padding-left: 3px;
  padding-right: 4px;
}

.bee-checkbox-label .bee-checkbox-label-required-star {
  position: absolute;
  right: 0px;
  padding-left: inherit;
  padding-right: inherit;
  max-width: 100%;
}

.bee-checkbox-value {
  display: inline-block;
  padding-left: 7px;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  max-width: -webkit-calc(100% - 20px);
  vertical-align: bottom;
}

.bee-checkbox-readOnly .bee-checkbox-value {
  vertical-align: text-top;
}

.bee-checkbox-disabled .bee-checkbox-input {
  pointer-events: none;
  opacity: 0.38;
}
