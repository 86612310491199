.alpha-vergabe-provider-table {
  .alpha-vergabe-provider-datatable {
    padding-top: 10px;
    padding-bottom: 10px;

    .p-datatable-wrapper,
    .p-datatable-scrollable-wrapper {
      border-radius: 4px;
      overflow: hidden;

      /* allg */
      .p-datatable-thead th {
        white-space: nowrap;
      }
      .p-datatable-thead th,
      .p-datatable-tbody td {
        text-overflow: ellipsis;
        overflow: hidden;
        div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      table {
        /* padding for first and last col */
        th:first-child,
        td:first-child {
          padding-left: 1rem !important;
        }

        th:last-child,
        td:last-child {
          padding-right: 1rem !important;
        }

        /* padding for header and body */

        .p-datatable-tbody td,
        .p-datatable-tfoot td {
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
          input {
            padding-top: 0em;
            padding-bottom: 0em;
          }
        }

        .p-datatable-emptymessage td {
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
        }
      }

      /* head */
      .p-datatable-scrollable-header-box,
      .p-datatable-scrollable-footer-box {
        margin-right: 0px !important;
      }
      th {
        background: var(--primary-color) !important;
        color: var(--primary-color-text);
      }

      tr {
        border-bottom: 1px solid #e4e4e4;
        td {
          border-bottom: none;
        }
      }

      /* titlecol */

      tbody tr {
        position: relative;
      }

      /* bundle */
      .alpha-vergabe-provider-dt-dl-col {
        width: 35%;
        .provider-name {
          font-size: 0.9em;
          color: var(--surface-500);
        }
      }

      /* bundle */
      .alpha-vergabe-provider-dt-bundle-col {
        width: 38%;
        max-width: 15em;
      }

      /* sums */
      .alpha-vergabe-provider-dt-sum-col {
        width: 50%;
        text-align: right;

        input {
          border: none;
          background: transparent;
          text-align: right;
        }
      }
      .alpha-vergabe-provider-dt-info-col {
        width: 5em;
      }
    }
  }
}
