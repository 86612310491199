.bee-icon-primary {
  color: var(--primary-color) !important;
}

.bee-icon-secondary {
  color: var(--secondary-dark-color) !important;
}
.bee-icon-light {
  color: var(--primary-color-text) !important;
}

.bee-icon-small {
  font-size: small;
}

.bee-icon-large {
  font-size: x-large;
}

.bee-icon-xxLarge {
  font-size: xx-large;
}
