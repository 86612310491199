/* headline */
.bee-headline-secondary {
  color: var(--secondary-dark-color);
}

/* button */

.p-button.p-button-secondary:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
  background-color: var(--secondary-light-color);
}

.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary
  > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary
  > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(
    .p-disabled
  ):hover {
  background-color: var(--secondary-color-16);
}

.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
  background-color: var(--primary-color-12);
}

/* progress spinner */

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--spinner-animation-start);
  }
  40% {
    stroke: var(--spinner-animation-40);
  }
  66% {
    stroke: var(--spinner-animation-66);
  }
  80%,
  90% {
    stroke: var(--spinner-animation-end);
    stroke: var(--spinner-animation-end);
  }
}

/* logo */
.header .logo {
  width: clamp(2rem, 50vw, 200px) !important;
  height: 3.5rem !important;
  margin: 0.5rem 0 0.75rem 0.5rem !important;
  display: inline-block !important;
  background: url(../Images/beestate_logo.svg) 50% 50% no-repeat !important;
}

.login-page .logo,
.forgot-pw-page .logo {
  width: clamp(2rem, 80vw, 16rem) !important;
  height: 5.5rem !important;
  margin: 0.5rem 0 0.5rem -0.5rem !important;
  display: inline-block !important;
  background: url(../Images/beestate_logo_dark.svg) 50% 50% no-repeat !important;
}

/* table */
td.p-frozen-column {
  background-color: var(--primary-color-12);
}

/* icon */
i:before {
  font-weight: 500 !important;
}

/* * {
  outline: 1px dashed red;
}
 */
